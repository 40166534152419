<template>
  <div class="notificationManagement">
    <div class="flex-box">
      <div>
        <el-radio-group v-model="tableType"
                        @change="loadAllNotice(1)">
          <el-radio-button :label="0">通知公告</el-radio-button>
          <el-radio-button :label="1">草稿</el-radio-button>
        </el-radio-group>
      </div>
      <div>
        <el-input placeholder="请输入关键词"
                  v-model="queryName"
                  class="input-box"></el-input>
        <el-button @click="loadAllNotice(1)"
                   type="primary">查 询</el-button>
        <el-button @click="$router.push({name:'editNotification'})"
                   type="success">创建通知</el-button>
      </div>
    </div>
    <el-table :data="allNotice">
      <el-table-column label="标题"
                       prop="title"
                       align="center">
      </el-table-column>
      <el-table-column prop="content"
                       label="内容"
                       align="center"></el-table-column>
      <!-- <el-table-column prop="statusStr"
        label="状态"
        align="center"></el-table-column> -->
      <el-table-column prop="opername"
                       label="发布人"
                       align="center"></el-table-column>
      <el-table-column prop="opertime"
                       label="时间"
                       align="center"></el-table-column>
      <el-table-column label="操作"
                       width="200px"
                       align="center">
        <template slot-scope="scope">
          <el-button type="primary"
                     @click="viewNotice(scope.row)">查 看</el-button>
          <el-button type="danger"
                     @click="deleteNotice(scope.row.id,scope.$index)">删 除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background
                   layout="prev,pager,next"
                   :page-count="page.pageCount"
                   :page-size="page.currentPage"
                   @current-change="loadAllNotice"></el-pagination>
  </div>
</template>

<script>
export default {
  name: "notificationManagement",
  data() {
    return {
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      allNotice: [],
      page: {
        pageCount: 0,
        currentPage: 1,
      },
      queryName: "",
      tableType: 0,
    };
  },
  created() {
    if (this.$route.query.type) {
      this.tableType = this.$route.query.type;
    }
    this.loadAllNotice(1);
  },
  methods: {
    loadAllNotice(page) {
      const that = this;
      let data = {
        name: this.queryName,
        indexNo: page,
        status: this.tableType,
      };
      this.$post("/base/queryAllNotice.do", data)
        .then((res) => {
          that.page.currentPage = page;
          that.page.pageCount = res.data.pageCount;
          that.allNotice = res.data.rows;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    viewNotice(notice) {
      if (this.tableType === 0) {
        this.$router.push({
          name: "noticePage",
          query: {
            noticeTitle: notice.title,
            noticeOperName: notice.opername,
            noticeOperTime: notice.opertime,
            noticeContent: notice.content,
            noticeReceiver: notice.mname,
            noticeFile: notice.file,
            noticeFileUrl: notice.url,
          },
        });
      } else {
        this.$router.push({
          name: "editNotification",
          query: {
            status: this.tableType,
            noticeId: notice.id,
          },
        });
      }
    },
    deleteNotice(id, index) {
      const that = this;
      this.$confirm("是否删除改通知", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("base/deleteNotice.do", { id })
            .then((res) => {
              that.$message({
                type: "success",
                message: res.message,
              });
              this.allNotice.splice(index, 1);
            })
            .catch((err) => {
              that.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消重置",
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.input-box {
  width: 200px;
  margin-right: 5px;
}
.flex-box {
  display: flex;
}
</style>
